.App {
    text-align: center;
    background-color: #282c34;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .App .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App button {
    height: 30px;
    padding: 0 15px;
    border-radius: 3px;
    border: none;
    background: #61dafb;
    color: #fff;
    font-size: 17px;
  }
  
  .App button:hover {
    cursor: pointer;
    background: #fff;
    color: #61dafb;
  }
  
  .App h1 {
    color: #fff;
  }
  
  .App-logo {
    height: 80px;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #004f9f;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-topHeader {
    background-color: #000000;
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-button {
    height: 30px;
    padding: 0 15px;
    border-radius: 3px;
    border: none;
    background: #61dafb;
    color: #fff;
    font-size: 17px;
  }

  .App-body {
    display: flex;
    justify-content: flex-end;
  }
  
  .contentBody{
    font-size: calc(8px + 1.3vmin);
    color: rgb(0, 0, 0);
    padding: 2rem;
  }
  
  .App-button:hover {
    cursor: pointer;
    background: #fff;
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

/* import elias */
a{
  text-decoration-line: none;
  color: #000;
}
body{
  background-color: rgb(238, 238, 238);
  margin: 0 auto;
}
.tr1{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#Assinatura{

  width: 820px
}
#tituloid{
height: 3vh;
justify-content: center;

}
.lblNome{
  font-family:Century Gothic;
  font-size:16px;
  font-weight:bold;

}
.lblCargo{

  font-family:Century Gothic;
  font-size:14px;
}
.imgLogo{
  border-width:0px;
  height: 73px;
}
.Logo{
  height: 16vh;
}
.label2{
  font-family:Century Gothic;
  font-size:14px;
  font-weight:bold;
}
.label{
  font-family:Century Gothic;
  font-size:14px;
}
.td{
  width: 20vw;

}

.lblRodape{
  font-family:Century Gothic;
  font-size:14px;
  font-weight:bold;
}
/* .btnEditar{

}
.btnGerar{

} */
.headerAss{
  height: 16vh;
  background-color: black;
  display: flex; 
  justify-content: space-around;
}
.header2Dash{
  height: 8vh;
  background-color: rgb(0, 79, 159);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: #FFF;
}



.campo{
  display: flex; 
  justify-content: space-around;
}
.grade{
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerAss h1{
  padding: 2vh;
  color: white;

  
}
.header2 h3{
  color: #fff;
  padding: 0vh;

  
}
.box{
  background-color: white;
}
/* .btn{

  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  border: 2px solid #F7CA18;
  transition: all 0.4s cubic-bezier(0.42,0,0.58,1);
} */
.containerAss{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2vh;
}
.titulo{
  padding: 3.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table1{
width:600px ;
padding:0px;
margin:0px;
border:0px;
}
.table2{
width:400px;
padding:0px;
margin:0px;
border:0px;
}
.table3{
text-align:right;
}
.td1{
width: 600px;
text-align:left;
}
/* parte da animacao */
.jumbotron h1 {
color: #353535;
}
.mrng-60-top {
margin-top: 60px;
}
/* Global Button Styles */
a.animated-button:link, a.animated-button:visited {
position: relative;
display: block;
margin: 30px auto 0;
padding: 14px 15px;
color: #fff;
font-size:14px;
font-weight: bold;
text-align: center;
text-decoration: none;
text-transform: uppercase;
overflow: hidden;
letter-spacing: .08em;
border-radius: 0;
text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
-webkit-transition: all 1s ease;
-moz-transition: all 1s ease;
-o-transition: all 1s ease;
transition: all 1s ease;
}
a.animated-button:link:after, a.animated-button:visited:after {
content: "";
position: absolute;
height: 0%;
left: 50%;
top: 50%;
width: 150%;
z-index: -1;
-webkit-transition: all 0.75s ease 0s;
-moz-transition: all 0.75s ease 0s;
-o-transition: all 0.75s ease 0s;
transition: all 0.75s ease 0s;
}
a.animated-button:link:hover, a.animated-button:visited:hover {
color: #FFF;
text-shadow: none;
}
a.animated-button:link:hover:after, a.animated-button:visited:hover:after {
height: 450%;
}
a.animated-button:link, a.animated-button:visited {
position: relative;
display: block;
margin: 30px auto 0;
padding: 14px 15px;
color: #fff;
font-size:14px;
border-radius: 0;
font-weight: bold;
text-align: center;
text-decoration: none;
text-transform: uppercase;
overflow: hidden;
letter-spacing: .08em;
text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
-webkit-transition: all 1s ease;
-moz-transition: all 1s ease;
-o-transition: all 1s ease;
transition: all 1s ease;
}

/* Victoria Buttons */

a.animated-button.victoria-one {
border: 2px solid #D24D57;
}
a.animated-button.victoria-one:after {
background: #D24D57;
-moz-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
-ms-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
-webkit-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
transform: translateX(-50%) translateY(-50%) rotate(-25deg);
}
a.animated-button.victoria-two {
border: 2px solid #D24D57;
}
a.animated-button.victoria-two:after {
background: #D24D57;
-moz-transform: translateX(-50%) translateY(-50%) rotate(25deg);
-ms-transform: translateX(-50%) translateY(-50%) rotate(25deg);
-webkit-transform: translateX(-50%) translateY(-50%) rotate(25deg);
transform: translateX(-50%) translateY(-50%) rotate(25deg);
}
a.animated-button.victoria-three {
border: 2px solid #D24D57;
}
a.animated-button.victoria-three:after {
background: #D24D57;
opacity: .5;
-moz-transform: translateX(-50%) translateY(-50%);
-ms-transform: translateX(-50%) translateY(-50%);
-webkit-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);
}
a.animated-button.victoria-three:hover:after {
height: 140%;
opacity: 1;
}
a.animated-button.victoria-four {
border: 2px solid #D24D57;
}
a.animated-button.victoria-four:after {
background: #D24D57;
opacity: .5;
-moz-transform: translateY(-50%) translateX(-50%) rotate(90deg);
-ms-transform: translateY(-50%) translateX(-50%) rotate(90deg);
-webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg);
transform: translateY(-50%) translateX(-50%) rotate(90deg);
}
a.animated-button.victoria-four:hover:after {
opacity: 1;
height: 600% !important;
}
/* Sandy Buttons */

a.animated-button.sandy-one {
border: 2px solid #AEA8D3;
color: #FFF;
}
a.animated-button.sandy-one:after {
border: 3px solid #AEA8D3;
opacity: 0;
-moz-transform: translateX(-50%) translateY(-50%);
-ms-transform: translateX(-50%) translateY(-50%);
-webkit-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);

}
a.animated-button.sandy-one:hover:after {
height: 120% !important;
opacity: 1;
color: #FFF;
}
a.animated-button.sandy-two {
border: 2px solid #AEA8D3;
color: #FFF;
}
a.animated-button.sandy-two:after {
border: 3px solid #AEA8D3;
opacity: 0;
-moz-transform: translateY(-50%) translateX(-50%) rotate(90deg);
-ms-transform: translateY(-50%) translateX(-50%) rotate(90deg);
-webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg);
transform: translateY(-50%) translateX(-50%) rotate(90deg);
}
a.animated-button.sandy-two:hover:after {
height: 600% !important;
opacity: 1;
color: #FFF;
}
a.animated-button.sandy-three {
border: 2px solid #AEA8D3;
color: #FFF;
}
a.animated-button.sandy-three:after {
border: 3px solid #AEA8D3;
opacity: 0;
-moz-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
-ms-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
-webkit-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
transform: translateX(-50%) translateY(-50%) rotate(-25deg);
}
a.animated-button.sandy-three:hover:after {
height: 400% !important;
opacity: 1;
color: #FFF;
}
a.animated-button.sandy-four {
border: 2px solid #AEA8D3;
color: #FFF;
}
a.animated-button.sandy-four:after {
border: 3px solid #AEA8D3;
opacity: 0;
-moz-transform: translateY(-50%) translateX(-50%) rotate(25deg);
-ms-transform: translateY(-50%) translateX(-50%) rotate(25deg);
-webkit-transform: translateY(-50%) translateX(-50%) rotate(25deg);
transform: translateY(-50%) translateX(-50%) rotate(25deg);
}
a.animated-button.sandy-four:hover:after {
height: 400% !important;
opacity: 1;
color: #FFF;
}
/* Gibson Buttons */

a.animated-button.gibson-one {
border: 2px solid #65b37a;
color: #FFF;
}
a.animated-button.gibson-one:after {
opacity: 0;
background-image: -webkit-linear-gradient( transparent 50%, rgba(101,179,122,0.2) 50%);
background-image: -moz-linear-gradient(transparent 50%, rgba(101,179,122,0.2) 50%);
background-size: 10px 10px;
-moz-transform: translateX(-50%) translateY(-50%) rotate(25deg);
-ms-transform: translateX(-50%) translateY(-50%) rotate(25deg);
-webkit-transform: translateX(-50%) translateY(-50%) rotate(25deg);
transform: translateX(-50%) translateY(-50%) rotate(25deg);
}
a.animated-button.gibson-one:hover:after {
height: 600% !important;
opacity: 1;
color: #FFF;
}
a.animated-button.gibson-two {
border: 2px solid #65b37a;
color: #FFF;
}
a.animated-button.gibson-two:after {
opacity: 0;
background-image: -webkit-linear-gradient( transparent 50%, rgba(101,179,122,0.2) 50%);
background-image: -moz-linear-gradient(transparent 50%, rgba(101,179,122,0.2) 50%);
background-size: 10px 10px;
-moz-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
-ms-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
-webkit-transform: translateX(-50%) translateY(-50%) rotate(-25deg);
transform: translateX(-50%) translateY(-50%) rotate(-25deg);
}
a.animated-button.gibson-two:hover:after {
height: 600% !important;
opacity: 1;
color: #FFF;
}
a.animated-button.gibson-three {
border: 2px solid #65b37a;
color: #FFF;
}
a.animated-button.gibson-three:after {
opacity: 0;
background-image: -webkit-linear-gradient( transparent 50%, rgba(101,179,122,0.2) 50%);
background-image: -moz-linear-gradient(transparent 50%, rgba(101,179,122,0.2) 50%);
background-size: 10px 10px;
-moz-transform: translateX(-50%) translateY(-50%) rotate(90deg);
-ms-transform: translateX(-50%) translateY(-50%) rotate(90deg);
-webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
a.animated-button.gibson-three:hover:after {
height: 600% !important;
opacity: 1;
color: #FFF;
}
a.animated-button.gibson-four {
border: 2px solid #65b37a;
color: #FFF;
}
a.animated-button.gibson-four:after {
opacity: 0;
background-image: -webkit-linear-gradient( transparent 50%, rgba(101,179,122,0.2) 50%);
background-image: -moz-linear-gradient(transparent 50%, rgba(101,179,122,0.2) 50%);
background-size: 10px 10px;
-moz-transform: translateX(-50%) translateY(-50%);
-ms-transform: translateX(-50%) translateY(-50%);
-webkit-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);
}
a.animated-button.gibson-four:hover:after {
height: 600% !important;
opacity: 1;
color: #FFF;
}
/* Thar Buttons */

a.animated-button.thar-one {
color: #fff;
cursor: pointer;
display: block;
position: relative;
border: 2px solid #F7CA18;
transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
a.animated-button.thar-one:hover {
color: #000 !important;
background-color: transparent;
text-shadow: none;
}
a.animated-button.thar-one:hover:before {
bottom: 0%;
top: auto;
height: 100%;
}
a.animated-button.thar-one:before {
display: block;
position: absolute;
left: 0px;
top: 0px;
height: 0px;
width: 100%;
z-index: -1;
content: '';
color: #000 !important;
background: #F7CA18;
transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
a.animated-button.thar-two {
color: #fff;
cursor: pointer;
display: block;
position: relative;
border: 2px solid #F7CA18;
transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
a.animated-button.thar-two:hover {
color: #000 !important;
background-color: transparent;
text-shadow: ntwo;
}
a.animated-button.thar-two:hover:before {
top: 0%;
bottom: auto;
height: 100%;
}
a.animated-button.thar-two:before {
display: block;
position: absolute;
left: 0px;
bottom: 0px;
height: 0px;
width: 100%;
z-index: -1;
content: '';
color: #000 !important;
background: #F7CA18;
transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
a.animated-button.thar-three {
color: rgb(0, 0, 0);
cursor: pointer;
display: block;
position: relative;
border: 2px solid rgb(0, 79, 159);
transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-three:hover {
color: #000 !important;
background-color: transparent;
text-shadow: nthree;
}
a.animated-button.thar-three:hover:before {
left: 0%;
right: auto;
width: 100%;
}
a.animated-button.thar-three:before {
display: block;
position: absolute;
top: 0px;
right: 0px;
height: 100%;
width: 0px;
z-index: -1;
content: '';
color: #000 !important;
background: rgb(0, 79, 159);
transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);

}
a.animated-button.thar-four {
color: #fff;
cursor: pointer;
display: block;
position: relative;
border: 2px solid #F7CA18;
transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);

}
a.animated-button.thar-four:hover {
color: #000 !important;
background-color: transparent;
text-shadow: nfour;
}
a.animated-button.thar-four:hover:before {
right: 0%;
left: auto;
width: 100%;
}
a.animated-button.thar-four:before {
display: block;
position: absolute;
top: 0px;
left: 0px;
height: 100%;
width: 0px;
z-index: -1;
content: '';
color: #000 !important;
background: #F7CA18;
transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);

}
/* https://<API-Server>/odata/v2/PerPersonal?
$filter=firstName like 'Ca%' or lastName 
like 'Ca%'&$select=firstName,lastName&
$format=JSON

https:/api19.sapsf.com/odata/v2/PerPersonal?
$filter=firstName like 'Ca%' or lastName 
like 'Ca%'&$select=firstName,lastName&
$format=JSON





api19.sapsf.com
https://%3Capi-server%3E/odata/v2/PerPersonal? */
  