.AppSign {
    display: flex;
    align-items: center;
  }

.AppContentLeft {
    flex: 3;
    background-color: #000000;
    height: 100vh;
    width: 130vh;
    
  }

  .container{
    width: 1000px;
    height: 150px;
    background: linear-gradient(0, #120c56, #000000);
  }

  .stars{
    position: relative;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    box-shadow: 50px 30px white,
      100px 80px white,
      80px 120px white,
      300px 20px white,
      250px 130px white,
      200px 50px white,
      150px 100px white,
      320px 100px white;
      animation: anim-stars 20s linear infinite;
  }

  .stars::after{
    content: " ";
    position: absolute;
    top: 150px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    box-shadow: 50px 30px white,
      100px 80px white,
      80px 120px white,
      300px 20px white,
      250px 130px white,
      200px 50px white,
      150px 100px white,
      320px 100px white;
  }

  .stars2{
    position: relative;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    box-shadow: 15px 15px white,
      245px 155px white,
      170px 180px white,
      1310px 220px white,
      275px 190px white,
      230px 10px white,
      120px 130px white,
      300px 130px white,
      220px 115px white;
    animation: anim-stars 20s linear infinite;
  }
  
  .stars2::after{
    content: " ";
    position: absolute;
    top: 150px;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    box-shadow: 15px 15px white,
      125px 135px white,
      150px 180px white,
      10px 1120px white,
      275px 190px white,
      230px 110px white,
      120px 230px white,
      300px 230px white,
      220px 215px white;
  }

  .stars3{
    position: relative;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    box-shadow: 50px 30px white,
      200px 190px white,
      190px 220px white,
      400px 130px white,
      350px 230px white,
      300px 160px white,
      250px 200px white,
      420px 200px white;
      animation: anim-stars 20s linear infinite;
  }

  .stars3::after{
    content: " ";
    position: absolute;
    top: 150px;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    box-shadow: 15px 15px white,
      225px 135px white,
      150px 180px white,
      110px 220px white,
      375px 190px white,
      330px 110px white,
      320px 230px white,
      400px 230px white,
      320px 215px white;
  }

  .stars4{
    position: relative;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    box-shadow: 50px 30px white,
      400px 390px white,
      390px 420px white,
      600px 330px white,
      550px 530px white,
      500px 360px white,
      550px 500px white,
      620px 400px white;
      animation: anim-stars 20s linear infinite;
  }

  .stars4::after{
    content: " ";
    position: absolute;
    top: 150px;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    box-shadow: 15px 15px white,
      425px 335px white,
      350px 380px white,
      310px 420px white,
      575px 390px white,
      530px 310px white,
      520px 430px white,
      600px 430px white,
      520px 415px white;
  }

  .stars5{
    position: relative;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    box-shadow: 50px 30px white,
      600px 590px white,
      590px 620px white,
      800px 530px white,
      750px 730px white,
      700px 560px white,
      750px 700px white,
      820px 600px white;
      animation: anim-stars 20s linear infinite;
  }

  .stars5::after{
    content: " ";
    position: absolute;
    top: 150px;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    box-shadow: 15px 15px white,
      625px 535px white,
      550px 580px white,
      510px 620px white,
      775px 590px white,
      730px 510px white,
      720px 630px white,
      800px 630px white,
      720px 615px white;
  }

  @keyframes anim-stars {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-150px);
    }
}

  .AppContentLogo {
    align-items: center;
    height: 700px;
    pointer-events: none;
  
 }

.AppContentRight {
    flex: 1;
    padding: 10px;
  }


.buttonSign {
    height: 3rem;
    width: 35rem;
    padding: 0 15px;
    border-radius: 3px;
    border: none;
    background: #3a54ff;
    color: #fff;
    font-size: 17px;
    transition: 0.5s;
  }

  .buttonSign:hover {
    cursor: pointer;
    background: #000;
    color: #fff;

  }


  